(function(){

	var body = $('body');

	var botao = $('[data-ativador="true"]');

	botao.on('click',function(){
		body.toggleClass('menu-active')
	})

})();