var Lazyimage = (function() {
	function Lazyimage() {
		var items 	= $('lazy-image');
		var loaded 	= this.loaded;

		items.on('lazyImage.loaded', function() {
			loaded(this);
		});

		this.register(items);

	}

	Lazyimage.prototype.loaded = function(el) {
		$(el).addClass('loaded');
		el.setAttribute('loaded', '');
	}

	Lazyimage.prototype.register = function(items) {

		if(window.IntersectionObserver) {
			const observer = new IntersectionObserver((entries) => {
			    $(entries).each(function (index, entry) {
			    	var lazy = $(entry.target);

			    	lazy.data('lazyImage', {
			    		src    : lazy.attr('src'),
			    		alt    : lazy.attr('alt'),
			    		loaded : false
			    	})

			        if (entry.isIntersecting === true) {
			        	let src = lazy.attr('src');
			        	let alt = lazy.attr('alt');

			        	const img = document.createElement('img');

			        	img.src = src;
			        	img.alt = alt;

			        	entry.target.appendChild(img);

			        	img.addEventListener('load', function() {
			        		$(entry.target).trigger('lazyImage.loaded');
			        	});


			        	observer.unobserve(entry.target)
			        }
			    });
			});

			items.each(function (index, item) {
				item.registred = true;
			    observer.observe(item, index);
			});

		} else {
			items.each(function() {
				let src = $(this).attr('src');
				let alt = $(this).attr('alt');
				let width = $(this).css('--w');
				let height = $(this).css('--h');

				$(this).append(`<img src="${src}" width="${width}" height="${height}" alt="${alt}">`).addClass('loaded');
				this.setAttribute('loaded','');
			});
		}
	};

	return new Lazyimage();
})();
